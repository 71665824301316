#social-box {
    position: fixed;
    top: 15%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    height: 50px;
    z-index: 9;
}

#social-box > a {
    height: 50px;
    width: 185px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem;
    border-radius: 0 10px 10px 0;
    transform: translateX(-68%);
    transition: all 1s;
    font-weight: 500;

    &:hover {
        transform: translate(0);
        transition: all .2s;
    }
}

#social-box > a.blue {
    background-color: #007ab9;
}

#social-box > a.green {
    background-color: #24cc63;
}

#social-box > a.rose {
    background-color: #ed00be;
}

#social-box > a > span {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#social-box > a > span > svg {
    height: 70%;
    width: 70%;
    color: #fff;
}

@media (max-width: 900px) {
    #social-box {
        top: 50%;
    }
}