a.button-style, button.button-style {
    text-decoration: none;
    font-size: 1.2rem;
    background-color: var(--color-blue-1);
    color: var(--color-white);
    width: 210px;
    height: 40px;
    border-radius: 10px;
    display: grid;
    place-items: center;
    transition: all 1s;

    &:hover {
        background-color: var(--color-blue-3);
        transition: all .2s;
        transform: scale(1.05);
    }
}