@import url('../../Variables.css');

#contact {
    height: 65vh;
    min-height: 500px;
    width: 100vw;
    padding-top: 25px;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#contact p {
    font-size: 3rem;
    color: var(--color-blue-1);
    font-weight: 500;
}

#contact h2 {
    font-size: 1rem;
    font-weight: 400;
}

#contact #form-contact {
    height: 400px;
    width: 80%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

#contact #form-contact .form-nameandemail {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#contact #form-contact .form-nameandemail > input {
    width: 48%;
    height: 35px;
    padding: 0 10px ;
    border: none;
    background-color: var(--color-blue-2);
    border-radius: 5px;
    font-size: 1.02rem;

    &:focus {
        outline: none;
    }
}

#contact #form-contact > input {
    width: 100%;
    height: 35px;
    padding: 0 10px ;
    border: none;
    background-color: var(--color-blue-2);
    border-radius: 5px;
    font-size: 1.02rem;

    &:focus {
        outline: none;
    }
}

#contact #form-contact > textarea {
    width: 100%;
    height: 150px;
    background-color: var(--color-blue-2);
    border-radius: 5px;
    padding: 10px;
    border: none;
    resize: none;
    font-size: 1.02rem;

    &:focus {
        outline: none;
    }
}



#contact #form-contact .btn-box-form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

#contact #form-contact .btn-box-form button {
    width: auto;
    min-width: 170px;
    padding: 10px;
    border: none;
    cursor: pointer;

}

@media (max-width: 900px) {
    #contact {
        height: auto;
        padding: 20px 0;
    }

    #contact #form-contact {
        height: auto;
        gap: 10px;
    }

    #contact #form-contact .form-nameandemail {
        flex-direction: column;
        gap: 10px;
    }

    #contact #form-contact .form-nameandemail > input {
        width: 100%;
    }

    #contact p {
        font-size: 1.8rem;
        color: var(--color-blue-1);
        font-weight: 500;
        width: 80%;
    }
    
    #contact h2 {
        font-size: .8rem;
        font-weight: 400;
        width: 80%;
    }
}