@import url('../../Variables.css');

#navbar {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar > div {
    height: 100%;
    width: 80%;
    max-width: 1300px;
    min-width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#navbar > div #btn-menu {
    display: none;
}

#navbar .menu-itens-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;    
}

#navbar .menu-itens-navbar .itens-navbar {
    list-style: none;
}
#navbar .menu-itens-navbar .itens-navbar a{
    color: var(--color-blue-3);
    text-decoration: none;
}


#navbar > div .img-box-navbar {
    height: 80%;
}

#navbar > div .img-box-navbar img {
    height: 100%;
}


@media (max-width: 900px) {
    
    #navbar {
        padding-top: 20px;
        position: relative;
    }

    #navbar > div {
        height: 100%;
        width: 80%;
        max-width: 100vw;
        min-width: 300px;
        justify-content: space-around;
    }

    #navbar > div #btn-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 7px;
        background-color: transparent;
        border: none;
        width: 40px;
        height: 40px;
    }

    #navbar > div #btn-menu span.line {
        width: 30px;
        height: 3px; 
        background-color: var(--color-blue-3);
        border-radius: 5px;
        transition: all .5s;
    }

    #navbar > div .menu-itens-navbar {
        position: absolute;
        top: 80px;
        left: 0;
        height: 100vh;
        width: 100vw;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 9;
        background-color: var(--color-white);
        padding-top: 20px;

        transition: all 1s;
        transform: translateX(-100%);
        opacity: 0;
        pointer-events: none;
    }

    #navbar.navbar-active > div .menu-itens-navbar {
        transition: all .2s;
        transform: translateX(0);
        opacity: 1;
        pointer-events: all;
    }

    #navbar.navbar-active div #btn-menu .line1 {
        transform: translate3d(0,13px,0) rotate(-315deg);
    }

    #navbar.navbar-active div #btn-menu .line2 {
        transform: scale(0);
    }

    #navbar.navbar-active div #btn-menu .line3 {
        transform: translate3d(0,-7px,0) rotate(-225deg);
    }
}