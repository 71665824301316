@import url('../../Variables.css');

#presentation {
    height: 70vh;
    min-height: 600px;
    width: 100vw;
    display: grid;
    place-items: center;
}

#presentation > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-width: 1250px;
    min-width: 900px;
}

#presentation .text-box-presentation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}

#presentation .text-box-presentation h1 {
    font-size: 4.5rem;
    text-align: left;
    font-weight: bolder;
    color: var(--color-blue-1);
}

#presentation .text-box-presentation p {
    color: var(--color-blue-1);
    font-size: 1.1rem;
}

#presentation .img-box-presentation {
    height: 500px;
    width: 450px;
    transition: transform 1s;
    
    &:hover {
        transition: transform .2s;
        transform: scale(1.05);
    }
}

#presentation .img-box-presentation img {
    height: 100%;
    width: 100%;
    transform: translateY(0px);
    animation: float 5s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@media (max-width: 900px) {

    #presentation {
        height: fit-content;
        min-height: 600px;
        width: 100vw;
        display: grid;
        place-items: center;
        padding: 20px 10px;
    }
    
    #presentation > div {
        flex-direction: column;
        width: 90%;
        max-width: 900px;
        min-width: 300px;
        gap: 30px;
    }

    #presentation .text-box-presentation {
        gap: 30px;
    }
    
    #presentation .text-box-presentation h1 {
        font-size: 2.5rem;
    }
    
    #presentation .text-box-presentation p {
        font-size: 1.1rem;
    }
    
    #presentation .img-box-presentation {
        height:350px;
        width: 300px;        
    }
}