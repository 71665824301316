@import url('../../Variables.css');

#CoursesAndSolutions {
    height: 200px;
    width: 100%;
    background-color: var(--color-blue-1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

#CoursesAndSolutions h2 {
    font-size: 2.5rem;
    color: var(--color-white);
}

#CoursesAndSolutions p {
    font-size: 1.1rem;
    color: var(--color-white);
}

@media (max-width: 900px) {
    #CoursesAndSolutions h2 {
        text-align: center;
        font-size: 1.7rem;
        padding: 5px;
    }
    
    #CoursesAndSolutions p {
        text-align: center;
        font-size: .9rem;
        padding: 5px;
    }
}