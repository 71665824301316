@import url('../../Variables.css');

#courses {
    height: 70vh;
    min-height: 700px;
    background-color: var(--color-blue-1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

#courses .text-box-courses {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

#courses .text-box-courses h2 {
    color: var(--color-white);
    font-size: 2rem;
    font-weight: 600;
}

#courses .text-box-courses p {
    font-size: 1.2rem;
    width: 70%;
    color: var(--color-white);
    text-align: center;
}

#courses .courses-container { 
    width: 65%;
    max-width: 1300px;
    min-width: 950px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#courses .courses-container .navigate-btns-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#courses .courses-container .navigate-btns-box button {
    background-color: transparent;
    border: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

#courses .courses-container .navigate-btns-box button svg {
    height: 100%;
    width: 100%;
    color: var(--color-white);
}


#courses .courses-box-courses {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    padding: 10px;
    overflow-x: auto;
    scroll-behavior: smooth;
    position: relative;    
}

#courses .courses-box-courses::-webkit-scrollbar {
    display: none;
} 

#courses .courses-box-courses .Navigation-btn {
    position: absolute;
    z-index: 3;
}

#courses .courses-box-courses .Navigation-btn:nth-child(2) {
    right: 0;
    transform: rotate(180deg);
}

#courses .courses-box-courses .box-course { 
    height: 400px;
    width: 250px;
    min-width: 250px;
    position: relative;
    transition: all 1s;

    &:hover {
        transition: all .2s;
        transform: scale(1.03);
    }

    &::after {
        content: '';
        bottom: 0;
        position: absolute;
        background: linear-gradient(to top, #118cac, transparent);
        width: 100%;
        height: 200px;
    }
}

#courses .courses-box-courses .box-course .img-box-course { 
    height: 100%;
    width: 100%;
    position: absolute;
}

#courses .courses-box-courses .box-course .img-box-course img { 
    height: 100%;
    width: 100%;
    z-index: 1;
}

#courses .courses-box-courses .box-course .texts-box-course { 
    position: absolute;
    height: 85%;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

#courses .courses-box-courses .box-course .texts-box-course .tag-course-white { 
   color: var(--color-white);
   font-size: .7rem;
   text-transform: uppercase;
}

#courses .courses-box-courses .box-course .texts-box-course .tag-course-blue { 
    color: var(--color-blue-3);
    font-size: .7rem;
    text-transform: uppercase;
 }

#courses .courses-box-courses .box-course .texts-box-course .title-course-box { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

#courses .courses-box-courses .box-course .texts-box-course .title-course-box .title-course { 
    width: 100%;
    color: var(--color-white);
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

#courses .courses-box-courses .box-course .texts-box-course .title-course-box .tag2-course { 
    text-align: center;
    color: var(--color-white);
    background-color: var(--color-blue-1);
    padding: 3px 7px;
    border-radius: 10px;
}

@media (max-width: 900px) {

    #courses {
        height: auto;
        min-height: 700px;
        gap: 50px;
    }

    #courses .text-box-courses h2 {
        font-size: 1.8rem;
    }
    
    #courses .text-box-courses p {
        font-size: 1rem;
        width: 95%;
    }

    #courses .courses-container .navigate-btns-box {
       display: none;
    }

    #courses .courses-container { 
        width: 95%;
        min-width: 380px;
    }

    #courses .courses-box-courses .box-course { 
    
        &:hover {
            transform: scale(1);
        }
    }
}