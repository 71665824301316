@import url('../../Variables.css');

#videopresentation {
    height: 40vh;
    min-height: 400px;
    background-color: var(--color-white);
    display: grid;
    place-items: center;
}

#videopresentation > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    width: 50%;
    min-width: 900px;
    max-width: 1550px;
}

#videopresentation .video-box-videopresentation {
    height: 300px;
    width: 50%;
    max-width: 600px;
}

#videopresentation .video-box-videopresentation iframe {
}

#videopresentation .text-box-videopresentation {
    width: 50%;
    max-width: 800px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

#videopresentation .text-box-videopresentation p {
    color: var(--color-blue-3);
    font-size: 1.5rem;
}

@media (max-width: 900px) {

    #videopresentation {
        height: auto;
        min-height: 400px;
        background-color: var(--color-white);
        display: grid;
        place-items: center;
        padding: 30px 0;
    }
    
    #videopresentation > div {
        flex-direction: column;
        gap: 0px;
        width: 100%;
        min-width: 350px;
        gap: 25px;
        align-items: center;
        justify-content: center;
    }

    #videopresentation .video-box-videopresentation {
        height: 250px;
        width: 95%;
        max-width: 600px;
       
    }
    
    #videopresentation .text-box-videopresentation {
        width: 90%;
        height: auto;
        justify-content: flex-start;
        gap: 25px;
    }

    
    #videopresentation .text-box-videopresentation p {
        color: var(--color-blue-3);
        font-size: 1.3rem;
    }

}