@import url('../../Variables.css');

#soluctions {
    height: auto;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#soluctions .container-soluction {
    height: 400px;
    width: 100%;
    display: grid;
    place-items: center;
}

#soluctions .container-soluction-blue {
    background-color: var(--color-blue-2);
}

#soluctions .container-soluction .box-soluction {
    width: 80%;
    max-width: 1250px;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

#soluctions .container-soluction .box-soluction .img-box-soluction {
    width: 40%;
}

#soluctions .container-soluction .box-soluction .img-box-soluction img {
   width: 100%; 
   max-height: 400px;
   object-fit: contain;
   transition: all 1s;


   &:hover {
    transition: all .2s;
    transform: scale(1.05);
   }
}

#soluctions .container-soluction .box-soluction .text-box-soluction {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
}

#soluctions .container-soluction .box-soluction .text-box-soluction h3 {
    font-size: 1.8rem;
}

#soluctions .container-soluction .box-soluction .text-box-soluction p {
    font-size: 1.05rem;
}

@media (max-width: 900px) {
    #soluctions .container-soluction {
        height: auto;
        width: 100%;
        padding: 20px 0;
    }
    #soluctions .container-soluction .box-soluction {
        width: 95%;
        height: auto;
        flex-direction: column;
        gap: 10px;
    }

    #soluctions .container-soluction-blue .box-soluction {
        flex-direction: column-reverse;
    }

    #soluctions .container-soluction .box-soluction .img-box-soluction {
        width: 95%;
    }

    #soluctions .container-soluction .box-soluction .text-box-soluction {
        width: 95%;
        gap: 20px;
    }
}